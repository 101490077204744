<template>
  <base-page :isHome="true">
    <wb-notice-bar />
    <div class="wb-home-page">
      <banner />
      <ourSpecial />
      <choiceUs />
    </div>
  </base-page>
</template>

<script>
  import Banner from './banner.vue'
  import ourSpecial from './our-special.vue'
  import choiceUs from './choice-us.vue'
  import WbNoticeBar from '@/components/wb-notice-bar.vue'
  // import config from '@/config/index.js'
  export default {
    name: 'home-page',
    data() {
      return {
        // isHkWeb: config.isHkWeb
      }
    },
    components: {
      WbNoticeBar,
      Banner,
      ourSpecial,
      choiceUs
    }

  }
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
