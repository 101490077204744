<template>
  <div class="banner">
    <van-swipe
    :show-indicators="false"
    :touchable="true"
    :loop="true"
    :autoplay="5000"
    :stop-propagation="false"
    @change="getIndex"
    ref="bannerList">
      <van-swipe-item>
         <div class="main-banner">
            <div class="text-content">
              <p class="title">{{ $t('home.banner.title') }}</p>
              <p class="title">{{ $t('home.banner.title2') }}</p>
              <p class="desc">{{ $t('home.banner.desc') }}</p>
            </div>
            <div><img src="@imgs/home/banner_1.webp" draggable="false"/></div>
          </div>
      </van-swipe-item>
      <van-swipe-item v-for="item in list" :key="item.id">
        <div  class="other-banner" :style="{'background-image': `url(${!isPC?item.h5Url:item.pcUrl})`}" @click.stop="goUrl(item)" />
      </van-swipe-item>
    </van-swipe>
    <div class="indicators" v-show="list.length">
        <span v-for="item in (list.length+1)" :class="['indicator',activeIndex===(item-1)?'active':'']" :key="item" @click="setIndex(item)" />
    </div>
  </div>
</template>
<script>
  import { bannerList } from '@/api'
  import { Swipe, SwipeItem } from 'vant'
  import { debounce } from '@/utils/common.js'
  export default {
    name: 'banner',
    data() {
      return {
        list: [],
        isPC: window.innerWidth > 900, // 默认使用pc端主图
        activeIndex: 0,
        startX: 0
      }
    },
    components: {
      [Swipe.name]: Swipe,
      [SwipeItem.name]: SwipeItem
    },
    methods: {
      init() {
        bannerList({
          pageIndex: this.pageIndex,
          pageSize: 20,
        }).then(res => {
          if (res.code !== 0 || !res.data.records.length) return
          res.data.records.forEach(item => {
            const imgUrl = this.$i18n.locale === 'zh_HK' ? item.mobileImgTraditional : item.mobileImgSimple
            const webUrl = this.$i18n.locale === 'zh_HK' ? item.webImgTraditional : item.webImgSimple
            this.list.push({
              id: item.id,
              h5Url: imgUrl,
              pcUrl: webUrl,
              link: item.jumpUrl
            })
          })
        })
      },
      // 获取当前切换张数
      getIndex(index){
        this.activeIndex = index
      },
      // 设置激活下标
      setIndex(item){
        this.activeIndex = --item
        this.$refs.bannerList.swipeTo(this.activeIndex)
      },
      goUrl(item){
        if (!item.link) return
        window.open(item.link)
      },
      setNewIndex(event){
        // 获取元素中间位置判断上移或下移
        event.stopPropagation()
        const endX = event.clientX || event.pageX || event.layerX
        const diff = endX - this.startX
        if (diff === 0) return
        if (diff < 0){
          this.$refs.bannerList.next()
          this.activeIndex = this.activeIndex === this.list.length ? 0 : ++this.activeIndex
        } else {
          this.$refs.bannerList.prev()
          this.activeIndex = this.activeIndex === 0 ? this.list.length : --this.activeIndex
        }
      },
      getPosition(event){
        this.startX = event.clientX || event.pageX || event.layerX
      },
      setResize: debounce(function(){
        this.isPC = window.innerWidth > 900
        // 解决UI验收banner在某区间未完全展示问题
        if (this.list.length > 0) {
          this.imageResize().then(res => {
            let height = window.innerWidth / res[0]
            for (let i = 0; i < this.list.length; i++){
              height = window.innerWidth > 900 ? (window.innerWidth > 1024 ? 620 : 496) : height
              document.querySelectorAll('.other-banner')[i].style.minHeight = `${height}px`
            }
          })
        }
      }, 100),
      imageResize(){
        return new Promise((resolve, reject) => {
          const imageListRatio = []
          for (let i = 0; i < this.list.length; i++){
            const image = new Image()
            image.onload = () => {
              const ratio = image.width / image.height
              imageListRatio.push(ratio)
              resolve(imageListRatio)
            }
            image.src = window.innerWidth < 900 ? this.list[i].h5Url : this.list[i].pcUrl
          }
        })
      }
    },
    mounted() {
      this.init()
      if (window.innerWidth > 1024){
        document.querySelector('.van-swipe').addEventListener('mousedown', this.getPosition)
        document.querySelector('.van-swipe').addEventListener('mouseup', this.setNewIndex)
      }
      window.addEventListener('resize', this.setResize)
    },
    beforeDestroy(){
      window.removeEventListener('resize', this.setResize)
      window.removeEventListener('mousedown', this.getPosition)
      window.removeEventListener('mouseup', this.setNewIndex)
    },
    updated(){
      this.setResize()
    }
  }
</script>
<style lang="scss" scoped>
.banner {
  background-color: #F5F7FA;
  width: 100%;
  position: relative;

  ::v-deep .van-swipe{
    text-align: center;
    img{
      width: 100%;
    }
    .title{
      font-size: 48px;
      font-weight: 600;
      line-height: 64px;
      color: #121212;
      flex-shrink: 0;
      text-align: left;
    }
    .desc{
      font-size: 22px;
      color: #333;
      margin-top: 24px;
      flex-shrink: 0;
      text-align: left;
      white-space: nowrap;
    }
    .main-banner{
      display:flex;
      align-items: center;
      justify-content: center;
      img{
        max-height: 426px;
        max-width: 630px;
      }
      padding-top: 100px;
    }
    .other-banner{
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      min-height: 620px;
    }
  }

  .indicators{
      position: absolute;
      bottom: 16px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0.3;
      .indicator{
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-right: 8px;
        border-radius: 50%;
        background-color: #121212;
        cursor: pointer;
      }
      .active{
        width: 20px;
        border-radius: 5px;
      }
    }
}
.min920{
  min-height: 920px;
}
@media screen and (max-width:767px) {
  .banner{
    ::v-deep .van-swipe{
      width: 100%;
      .title{
        font-size: 28px;
        line-height: 40px;
      }
      .desc{
        font-size: 14px;
        margin-top: 8px;
        margin-bottom: 48px;
      }
      .text-content{
        padding-left: 13px;
      }
      .main-banner{
        padding: 32px 0px 0px ;
        display: block;
        img{
          max-width: 375px;
        }
      }
      .other-banner{
        min-height: 540px;
        background-position:center top;
      }
    }
    .indicators{
      bottom: 8px;
      .indicator{
        width: 4px;
        height: 4px;
        margin-right: 4px;
      }
      .active{
        width: 12px;
        border-radius: 5px;
      }
    }
  }
}
@media  (min-width:768px) and (max-width:900px) {
  .banner{
    ::v-deep .van-swipe{
      width: 100%;
      .title{
        width: 336px;
        margin: 0 auto;
      }
      .desc{
        text-align: center;
        margin-top: 16px;
        margin-bottom: 120px;
      }
      .main-banner{
        padding: 70px 12px 0;
        display: block;
        img{
          width: auto;
        }
      }
      .other-banner{
        min-height: 983px;
        background-position: center top;
      }

    }
    .indicators{
      bottom: 24px;
    }

  }
}
@media  (min-width:1024px) and (max-width:1024px) {
  .banner{
    min-height: 496px;
    ::v-deep .van-swipe{
      width: 100%;
      .title{
       font-size: 38px;
       line-height: 51px
      }
      .desc{
        font-size: 18px;
        margin-top: 18px;
      }
      .main-banner{
        padding: 81px 12px 0px;
        justify-content: center;
        img{
          max-width: 504px;
        }
      }
      .other-banner{
        min-height: 496px;
      }
    }
  }
}
@media (min-width:450px) and (max-width:767px){ //解决再次区间图片未完全展示
  ::v-deep .van-swipe{
    .main-banner{
      img{
        max-width: 100%!important;
      }
    }
    .other-banner{
      min-height: 628px;
    }
  }
}
</style>
