<template>
  <div class="wb-notice-bar" v-if="list">
    <div class="limit-wrap">
      <van-notice-bar
        :scrollable="isScrollable"
        background="#e6f0fa"
        color="#0691ff"
        :class="{'text-center':!isScrollable}"
        >
        <span slot="default" @click="goUrl" :class="{'pointer':noticeId}">{{ list }}</span>
      </van-notice-bar>
    </div>
    <img class="close_icon" :src="closeIcon" @click="closeNotice" />
  </div>
</template>

<script>
  import { NoticeBar } from 'vant'
  import { getImportantInfo } from '@/api'
  export default {
    name: 'wb-notice-bar',
    data() {
      return {
        list: '',
        noticeId: '',
        isScrollable: true,
        barWidth: 300,
        isPc: window.innerWidth > 810,
        closeIcon: require('@/assets/images/common/icon_close_ad_blue.png')
      }
    },
    components: {
      [NoticeBar.name]: NoticeBar
    },
    methods: {
      getList() {
        getImportantInfo({ messageType: 0, state: 1 }).then(res => {
          if (res.code === 0 && res.data?.records?.length > 0) {
            const data = res.data.records[0]
            this.list = data.title
            this.noticeId = !data.contentIsNull ? data.id : ''
            this.$nextTick(() => {
              this.barWidth = document.querySelector('.van-notice-bar__content').clientWidth
              const windowWdith = window.innerWidth
              this.isScrollable = this.barWidth > windowWdith - 188
            })
          }
        })
      },
      closeNotice() {
        this.list = ''
        document.querySelector('.page-head').style.top = '0px'
        window.sessionStorage.setItem('noticeBar', false)
      },
      // 设置导航栏与content的距离顶部距离
      setTop() {
        const noticeBarHeight = document.querySelector('.wb-notice-bar') && document.querySelector('.wb-notice-bar').clientHeight || 0
        if (window.innerWidth >= 768) {
          this.closeIcon = require('@/assets/images/common/notice_close_icon.png')
        }
        document.documentElement.style.setProperty('--notice-bar', `${noticeBarHeight}px`)
      },
      goUrl(){
        if (this.noticeId){
          window.location.href = `./notice.html?id=${this.noticeId}`
        }
      }
    },
    mounted() {
      this.setTop()
      window.addEventListener('resize', this.setTop)
    },
    created() {
      this.getList()
    },
    updated() {
      this.setTop()
    }
  }
</script>

<style lang="scss" scoped>
.wb-notice-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  background-color: #e6f0fa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 80px;
  .limit-wrap {
    overflow: hidden;
    height: 52px;
    line-height: 52px;
    width: calc(100% - 20px);
    margin-right: 12px;
    ::v-deep .van-notice-bar{
      padding: 0;
      font-size: 15px;
      min-width:64px;
      height: 100%;
      &.text-center{
        .van-notice-bar__wrap{
          justify-content: center;
        }
      }
    }
  }
  .close_icon {
    width: 16px;
    height: 16px;
    flex: 0 0 auto;
  }
}
// 当屏幕小于750px
@media screen and (max-width:767px) {
  .wb-notice-bar {
    top: 56px;
    padding-right: 20px;
    padding-left: 20px;
    ::v-deep .van-notice-bar {
      font-size: 14px;
    }
    .limit-wrap {
      width: calc(100% - 20px);
      height: 28px;
      line-height: 28px;
      font-size: 14px;
    }
    .close_icon {
      width: 12px;
      height: 12px;
      flex: 0 0 auto;
    }
  }
}
@media (min-width:768px) and (max-width:1023px) {
  .wb-notice-bar{
    padding-left: 32px;
    padding-right: 32px;
    top: 0;
    ::v-deep .van-notice-bar{
      font-size: 15px;
    }
    .limit-wrap{
      margin-right: 21px;
    }
  }
}
</style>
