<template>
  <div class="our-special">
    <div>
      <h3 class="title">{{ $t('home.specialTitle') }}</h3>
      <div class="card" v-for="(item, index) in list" :key="index">
        <p class="bg" :style="{ 'background': item.bgColor, 'opacity': item.opacity }" />
        <div style="position:relative;z-index:2;">
           <img class="icon" :src="item.icon" />
          <p class="card-title">{{ item.title }}</p>
          <p class="desc">{{ item.subTitle }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import i18n from '@/language'
  export default {
    name: 'special',
    data() {
      return {
        list: [
          {
            icon: require('@imgs/home/special/icon_t1.png'),
            title: i18n.t('home.ourSpecial.title'),
            subTitle: i18n.t('home.ourSpecial.subTitle'),
            bgColor: 'linear-gradient(136deg, #80C7FF 0%, #0691FF 100%)',
            opacity: 0.9
          },
          {
            icon: require('@imgs/home/special/icon_t2.png'),
            title: i18n.t('home.ourSpecial.title2'),
            subTitle: i18n.t('home.ourSpecial.subTitle2'),
            bgColor: 'linear-gradient(135deg, #7FDDEB 0%, #00B5E8 100%)',
            opacity: 0.9
          },
          {
            icon: require('@imgs/home/special/icon_t3.png'),
            title: i18n.t('home.ourSpecial.title3'),
            subTitle: i18n.t('home.ourSpecial.subTitle3'),
            bgColor: 'linear-gradient(135deg, #7191BC 0%, #4A5F7B 100%)',
            opacity: 1
          }
        ]
      }
    }
  }
</script>
<style lang="scss" scoped>
.our-special {
  padding-top: 52px;
  padding-bottom: 100px;
  text-align: center;
  background-color: #F5F7FA;

  &>div {
    width: 1000px;
    margin: 0 auto;
  }

  .title {
    color: #333;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 32px;
  }

  .card {
    display: inline-block;
    width: 310px;
    height: 310px;
    border-radius: 12px;
    position: relative;
    color: #fff;
    padding-top: 74px;

    &:nth-child(3) {
      margin: 0 35px;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 113px;
      background: url('../../assets/images/home/special/bottom_bg.png') center/100% 100% no-repeat;
    }

    .card-title {
      font-size: 24px;
      font-weight: 600;
      margin-top: 30px;
    }

    .desc {
      font-size: 16px;
      line-height: 22px;
      margin-top: 8px;
    }

    .icon {
      width: 72px;
    }

    .bg {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      top: 0;
      border-radius: 12px;
    }
  }
}

@media screen and (max-width:767px) {
  .our-special {
    padding-top: 24px;
    padding-bottom: 24px;

    &>div {
      width: 100%;
      padding: 0 25px;
    }

    .title {
      color: #333;
      font-size: 20px;
      margin-bottom: 12px;
    }

    .card {
      display: block;
      width: 100%;
      height: 160px;
      border-radius: 12px;
      position: relative;
      color: #fff;
      padding-top: 28px;

      &:nth-child(3) {
        margin: 12px 0;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 113px;
        background: url('../../assets/images/home/special/bottom_bg.png') center/100% 100% no-repeat;
      }

      .card-title {
        font-size: 18px;
        font-weight: 500;
        margin-top: 16px;
      }

      .desc {
        font-size: 12px;
        line-height: 16px;
        margin-top: 4px;
      }

      .icon {
        width: 48px;
        height: 48px;
      }

      .bg {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        top: 0;
        border-radius: 12px;
      }
    }
  }
}

@media (min-width:768px) and (max-width:1023px) {
  .our-special {
    text-align: left;

    &>div {
      width: 100%;
      padding: 0 57px 0 56px;
    }

    .title,.card {
      text-align: center;
    }
    .card{
      &:nth-child(3) {
        margin: 0 0 35px 35px;
      }

    }
  }
}
@media (min-width:1024px) and (max-width:1024px) {
  .our-special{
    padding-bottom: 81px;
    .title{
      margin-bottom: 25px;
      font-size: 26px;
      line-height: 37px;
      font-weight: 500;
    }
    .card{
      width: 248px;
      height: 248px;
      border-radius: 10px;
      padding-top: 54px;
      &:nth-child(3) {
        margin: 0 28px;
      }
      .icon{
        width: 58px;
      }
      .card-title{
        margin-top: 24px;
        font-size: 19px;
      }
      .desc{
        font-size: 13px;
      }
    }
  }
}
</style>
