<template>
  <div class="choice-us">
    <div>
      <h3 class="title">{{ $t('home.choiceTitle') }}？</h3>
      <p class="sub-title">WHY CHOOSE WINBULL？</p>
      <div class="card" v-for="(item, index) in list" :key="index">
        <p class="mask" />
        <p class="bg" :style="{ 'backgroundImage': 'url(' + (isPc ? item.pcBg : item.h5Bg) + ')' }" />
        <div style="position:relative;z-index:3;">
          <p class="card-title">{{ item.title }}</p>
          <p class="desc">{{ item.desc }}</p>
          <p class="desc" v-if="item.desc2">{{ item.desc2 }}</p>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import i18n from '@/language'
  export default {
    name: 'special',
    data() {
      return {
        list: [
          {
            pcBg: require('@imgs/home/pc_1.png'),
            h5Bg: require('@imgs/home/h5_1.png'),
            title: i18n.t('home.choiceReason.title'),
            desc: i18n.t('home.choiceReason.desc'),
            desc2: i18n.t('home.choiceReason.desc2'),
          },
          {
            pcBg: require('@imgs/home/pc_2.png'),
            h5Bg: require('@imgs/home/h5_2.png'),
            title: i18n.t('home.choiceReason.title2'),
            desc: i18n.t('home.choiceReason.desc3'),
            desc2: i18n.t('home.choiceReason.desc4'),
          },
          {
            pcBg: require('@imgs/home/pc_3.png'),
            h5Bg: require('@imgs/home/h5_3.png'),
            title: i18n.t('home.choiceReason.title3'),
            desc: i18n.t('home.choiceReason.desc5')
          }
        ],
        isPc: window.innerWidth > 810
      }
    }
  }
</script>
<style lang="scss" scoped>
.choice-us {
  background-color: #E4EAF0;
  padding: 100px 0;
  margin: 0 auto;

  .card {
    display: inline-block;
    width: 310px;
    height: 360px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;

    padding-left: 30px;

    &:nth-last-child(2) {
      margin: 0 35px;
    }

    .mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      background: linear-gradient(161deg, rgba(0, 23, 41, 0.9) 0%, rgba(0, 23, 41, 0.1) 100%);
    }

    .bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .card-title {
      font-size: 30px;
      font-weight: 600;
      margin-top: 48px;
      margin-bottom: 10px;
      color: #fff;
      line-height: 42px;
    }

    .desc {
      font-size: 16px;
      color: #e6e6e6;
      line-height: 25px;
    }
  }

  &>div {
    width: 1000px;
    margin: 0 auto;

    .title {
      font-size: 40px;
      color: #121212;
      line-height: 56px
    }

    .sub-title {
      color: #cacaca;
      font-size: 18px;
      margin-top: 8px;
      margin-bottom: 60px;
    }
  }
}

@media screen and (max-width:765px) {
  .choice-us {
    padding: 40px 25px;

    .card {
      width: 100%;
      height: 200px;
      display: block;

      &:nth-last-child(2) {
        margin: 12px 0;
      }

      .mask {
        background: linear-gradient(161deg, rgba(0, 14, 26, 0.9) 30%, rgba(0, 0, 0, 0.1) 100%);
      }

      .card-title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 8px;
      }

      .desc {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &>div {
      width: 100%;

      .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
      }

      .sub-title {
        font-size: 11px;
        margin-top: 4px;
        margin-bottom: 24px;
      }
    }
  }
}

@media (min-width:765px) and (max-width:1024px) {
  .choice-us {

    .card {
      &:nth-last-child(2) {
        margin: 0 0 0 35px;
      }
      &:nth-last-child(1) {
        margin-top:35px;
      }
    }

    &>div {
      width: 100%;
      padding: 0 57px 0 56px;
    }
  }
}
@media (min-width:1023px) and (max-width:1024px){
  .choice-us {

    .card {
      width: 248px;
      height: 288px;
      padding-left: 24px;
      &:nth-last-child(2) {
        margin: 0 28px;
      }

      .card-title {
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 8px;
        margin-top: 39px;
      }

      .desc {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &>div {
      width: 100%;
      padding: 0 112px;
      .title {
        font-size: 32px;
        font-weight: 500;
        line-height: 45px;
      }

      .sub-title {
        font-size: 14px;
        margin-top: 6px;
        margin-bottom: 15px;
      }
    }
  }
}
</style>
